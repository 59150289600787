<template>
  <div>
    <form-component
      @onSubmit="onSubmit"
      :navigation-group="navigationGroup"
      :product-subcategories="productSubcategories"
      @fetchByLocale="fetchByLocale" />
  </div>
</template>

<script>
import FormComponent from '@/components/navigation-products/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { showProduct, updateOrCreateProducts } from '@/http/navigation';
import { all } from '@/http/product/subcategory';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      navigationGroup: {
        name: '',
        navigation_subgroups: [
          {
            name: '',
            navigation_elements: [
              {
                name: '',
              },
            ],
          },
        ],
      },
      productSubcategories: [],
    };
  },

  async mounted() {
    Promise.all([this.fetchProductSubcategories(), this.fetchNavigationGroup({ lang: 'pl' })]);
  },

  methods: {
    async fetchByLocale(lang) {
      await this.fetchNavigationGroup(lang);
    },
    async fetchProductSubcategories() {
      try {
        const { data } = await all();
        this.productSubcategories = data.data;
      } catch (e) {
        this.showErrorNotification('Problem z pobraniem danych', 'Nie udało się pobrać danych podkategorii.');
      }
    },
    async fetchNavigationGroup(lang) {
      try {
        const { data } = await showProduct(this.$route.params.id, lang);

        this.navigationGroup = data.data;
      } catch (error) {
        this.showErrorNotification('Problem z pobraniem danych', 'Nie udało się pobrać danych elementu nawigacji.');
      }
    },
    async onSubmit(data) {
      const form = new FormData();

      form.append('lang', data.lang);
      form.append('name', data.name);
      form.append('slug', data.slug);
      form.append('id', data.id);
      form.append('position', data.position);
      form.append('inspiration_link', data.inspiration_link);

      for (let i = 0; i < data.navigation_subgroups.length; i++) {
        form.append(`navigation_subgroups[${i}][name]`, data.navigation_subgroups[i].name);
        form.append(`navigation_subgroups[${i}][slug]`, data.navigation_subgroups[i].slug);
        form.append(`navigation_subgroups[${i}][id]`, data.navigation_subgroups[i].id);
        form.append(
          `navigation_subgroups[${i}][custom_filtering]`,
          data.navigation_subgroups[i].custom_filtering ?? '',
        );

        for (let j = 0; j < data.navigation_subgroups[i].navigation_elements.length; j++) {
          form.append(
            `navigation_subgroups[${i}][navigation_elements][${j}][name]`,
            data.navigation_subgroups[i].navigation_elements[j].name,
          );
          form.append(
            `navigation_subgroups[${i}][navigation_elements][${j}][slug]`,
            data.navigation_subgroups[i].navigation_elements[j].slug,
          );
          form.append(
            `navigation_subgroups[${i}][navigation_elements][${j}][id]`,
            data.navigation_subgroups[i].navigation_elements[j].id,
          );
          form.append(
            `navigation_subgroups[${i}][navigation_elements][${j}][custom_filtering]`,
            data.navigation_subgroups[i].navigation_elements[j].custom_filtering ?? '',
          );

          for (let k = 0; k < data.navigation_subgroups[i].navigation_elements[j].categories.length; k++) {
            form.append(
              `navigation_subgroups[${i}][navigation_elements][${j}][categories][${k}][id]`,
              data.navigation_subgroups[i].navigation_elements[j].categories[k].id,
            );
          }
        }
      }
      form.append('main_image', data.main_image.file, data.main_image.file.fileName);

      try {
        await updateOrCreateProducts(form);

        this.showSuccessNotification('Dane zapisane', 'Element nawigacji został zaktualizowany.');
        this.$router.push({ name: 'navigation-products-edit' });
      } catch (error) {
        const message =
          error.response.data.message ??
          'Wystąpił błąd podczas dodawania elementu nawigacji. Skontaktuj się ze swoim developerem.';
        this.showErrorNotification('Problem z zapisaniem danych', message);
      }
    },
  },
};
</script>
